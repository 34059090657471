import React, {useState} from 'react';
import './Input.scss'

function Input(props) {
    const {className, type, name, placeholder, label, value, disabled, error = null} = props
    const {onChange = () => {}, onError = () => {}} = props
    const [touched, setTouched] = useState(false)

    const updateInput = (e) => {
        if (!touched) {
            setTouched(true)
        }

        onError(e.target.value)
        onChange(e)
    }

    return (
        <div className={`input ${className || ''}`}>
            {label && <label htmlFor={name} className='input-label'>{label}</label> }
            <input
                className={'input-field'}
                type={type || 'text'}
                name={name}
                placeholder={placeholder || ''}
                value={value}
                disabled={disabled}
                onChange={updateInput}
                onBlur={() => setTouched(true)}
            />
            <div className='input-error'>
                {error ? error : null}
            </div>
        </div>
    );
}

export default Input;
