import React from 'react';
import LoginForm from "../Login/LoginForm/LoginForm";
import "./SignIn.scss"

function SignIn(props) {
	return (
		<div className="sign-in">
			<LoginForm />
		</div>
	);
}

export default SignIn;
