import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import ErrorMsg from "../../../components/ErrorMsg/ErrorMsg";
import {apiPostLogin} from "../../../api/account";
import {setToken} from "../../../store/local/store";
import {checkAccount} from "../../../store/redux/reducers/account/accountActions";
import {INVOICE_ROUTE} from "../../../components/Router/links";
import {errorHandler} from "../../../api/errorHandler";
import './LoginForm.scss'

function LoginForm() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const updateInput = (event) => {
        if (error) {
            setError('')
        }

        if (event.target.name === 'email') {
            return setEmail(event.target.value)
        }

        if (event.target.name === 'password') {
            return setPassword(event.target.value)
        }
    }

    // FIXME 2: set correct validation
    const isDisabled = loading || !email || !password || error

    const login = () => {
        setLoading(true)
        apiPostLogin({email, password})
            .then((response) => {
                setToken(response.data.token)
                dispatch(checkAccount())
                history.push(INVOICE_ROUTE)
            })
            .catch((error) => setError(errorHandler(error)))
            .finally(() => setLoading(false))
    }

    return (
        <div className='login-form'>
            <p className='login-form-title'>{t('login.title')}</p>
            <div className='login-form-divider'>{}</div>
            <div className='login-form-content'>
                <Input
                    className='login-input login-form-content-input'
                    name={'email'}
                    value={email}
                    onChange={updateInput}
                    placeholder={t('login.form.email')}
                />
                <Input
                    className='login-input login-form-content-input'
                    name={'password'}
                    value={password}
                    type={'password'}
                    onChange={updateInput}
                    placeholder={t('login.form.password')}
                />
            </div>
            <ErrorMsg className='login-form-error' error={error} />
            <Button className='login-form-button' onClick={() => login()} disabled={isDisabled}>
                {t('login.form.confirm')}
            </Button>
        </div>
    );
}

export default LoginForm;
