import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getToken} from "./store/local/store";
import {checkAccount, logoutAccount} from "./store/redux/reducers/account/accountActions";
import AppRouter from "./components/Router/Router";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import useIdle from "./hooks/useCheckUserActivity";


const INACTIVE_LOGIN_MS = 30 * (60 * 1000)

function App() {
    const dispatch = useDispatch()
    const {loading} = useSelector(state => state.account)
    const {email} = useSelector(state => state.account)

    useEffect(() => {
        const token = getToken()

        if (token) {
            dispatch(checkAccount()).then(r => {})
        } else {
            dispatch(logoutAccount())
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const isInactive = useIdle(INACTIVE_LOGIN_MS)

    if (loading) {
        return <h1>Загрузка...</h1>
    }

    if (isInactive) {
        if (email) {
            dispatch(logoutAccount())
        }
    }

    return (
        <div className="app">
            <BrowserRouter>
                <ScrollToTop>
                    <AppRouter/>
                </ScrollToTop>
            </BrowserRouter>
        </div>
    );
}

export default App;
