import React from 'react';
import './Button.scss'

function Button({className, onClick, children, disabled, hide = false}) {
    if (hide) {
        return ''
    }

    return (
        <button
            className={`button ${className || ''} ${disabled ? 'button-disabled' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

export default Button;
