import React from 'react';
import {useTranslation} from "react-i18next";
import './ErrorMsg.scss'

function ErrorMsg({className, error}) {
    const {t} = useTranslation()

    if (!error) {
        return ''
    }

    return (
        <p className={`error-msg ${className || ''}`}>
            {t(error)}
        </p>
    );
}

export default ErrorMsg;
