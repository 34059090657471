import { useEffect, useState } from 'react';
import { on, off } from "../utils/misc";
import { throttle } from '../utils/throttle';

const defaultEvents = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
const oneMinute = 60 * 1000;
const throttleTimeout = 1000;

// по примеру компонента https://github.com/streamich/react-use/blob/master/src/useIdle.ts
const useIdle = (ms = oneMinute, initialState= false, events = defaultEvents) => {
	const [state, setState] = useState(initialState);

	useEffect(() => {
		let mounted= true;
		let timeout;
		let localState= state;

		const set = (newState) => {
			if (mounted) {
				localState = newState;
				setState(newState);
			}
		};

		const onEvent = throttle(throttleTimeout, () => {
			if (localState) {
				set(false);
			}

			clearTimeout(timeout);
			timeout = setTimeout(() => set(true), ms);
		});

		const onVisibility = () => {
			if (!document.hidden) {
				onEvent();
			}
		};

		for (let i = 0; i < events.length; i++) {
			on(window, events[i], onEvent);
		}
		on(document, 'visibilitychange', onVisibility);

		timeout = setTimeout(() => set(true), ms);

		return () => {
			mounted = false;

			for (let i = 0; i < events.length; i++) {
				off(window, events[i], onEvent);
			}
			off(document, 'visibilitychange', onVisibility);
		};
	}, [ms, events]); // eslint-disable-line react-hooks/exhaustive-deps

	return state;
};

export default useIdle;
