import React from "react";
import {
    BALANCE_HISTORY_DETAIL_ROUTE,
    BALANCE_HISTORY_ROUTE,
    DASHBOARD_ROUTE,
    HOME_ROUTE,
    INVOICE_DETAIL_ROUTE,
    INVOICE_ROUTE,
    LOGIN_ROUTE,
    PAYOUT_ROUTE,
    SETTINGS_ROUTE,
} from "./links";
import SignIn from "../../pages/SignIn/SignIn";
import {app} from "../../config";


const Home = React.lazy(() => import("../../pages/Home/Home")
    .then(({default : Home}) => ({default: Home})));
const Dashboard = React.lazy(() => import("../../pages/Dashboard/Dashboard")
    .then(({default : Dashboard}) => ({default: Dashboard})));
const Login = React.lazy(() => import("../../pages/Login/Login")
    .then(({default : SignIn}) => ({default: SignIn})));
const Invoice = React.lazy(() => import("../../pages/Invoice/Invoice")
    .then(({default : Invoice}) => ({default: Invoice})));
const InvoiceDetail = React.lazy(() => import("../../pages/InvoiceDetail/InvoiceDetail")
    .then(({default : InvoiceDetail}) => ({default: InvoiceDetail})));
const Settings = React.lazy(() => import("../../pages/Settings/Settings")
    .then(({default : Settings}) => ({default: Settings})));
const Payout = React.lazy(() => import("../../pages/Payout/Payout")
    .then(({default : Payout}) => ({default: Payout})));
const BalanceHistory = React.lazy(() => import("../../pages/BalanceHistory/BalanceHistory")
    .then(({default : BalanceHistory}) => ({default: BalanceHistory})));
const BalanceHistoryDetail = React.lazy(() => import("../../pages/BalanceHistoryDetail/BalanceHistoryDetail")
    .then(({default : BalanceHistoryDetail}) => ({default: BalanceHistoryDetail})));


const addRoute = (path, component) => {
    return {
        path: path,
        Component: component,
    }
}

export const publicRoutes = [
    addRoute(HOME_ROUTE, Home),
]

if (!app.isLandingOnly) {
    publicRoutes.push(
        ...[addRoute(LOGIN_ROUTE, SignIn)]
    )
}

export let authRoutes = []

if (!app.isLandingOnly) {
    authRoutes = [
        addRoute(DASHBOARD_ROUTE, Dashboard),
        addRoute(INVOICE_ROUTE, Invoice),
        addRoute(SETTINGS_ROUTE, Settings),
        addRoute(PAYOUT_ROUTE, Payout),
        addRoute(BALANCE_HISTORY_ROUTE, BalanceHistory),
        addRoute(BALANCE_HISTORY_DETAIL_ROUTE, BalanceHistoryDetail),

        // must be last, or fix pattern /dashboard/:type/:id
        addRoute(INVOICE_DETAIL_ROUTE, InvoiceDetail),
    ]
}
