export function on(obj = null, ...args) {
	if (obj && obj.addEventListener) {
		obj.addEventListener(...(args));
	}
}

export function off(obj = null, ...args) {
	if (obj && obj.removeEventListener) {
		obj.removeEventListener(...(args));
	}
}
